<div class="flex flex-1 flex-col px-5">

  <div class="mb-4 flex justify-between items-center">
    <div>
      <h1 class="font-black text-[20px] text-black">Relatório {{ title }}</h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">
        Busque filtro para consultar com mais detalhes os relatórios
      </h6>
    </div>

  </div>
  <hr />


  <div class="w-full flex flex-col items-center">
    <section class="w-full flex flex-wrap">
      <div class="w-full flex flex-col">
        <div class="flex flex-1 flex-col">
          <div class="form-wrapper">
            <form [formGroup]="reportForm">
              <div class="form-content">
                <div class="field-group">
                  <div class="field-date">
                    <mat-form-field>
                      <mat-label>Período anterior</mat-label>
                      <mat-date-range-input [rangePicker]="pickerPrevious" formGroupName="dateRange">
                        <input matStartDate placeholder="Data inicial" formControlName="dateStartPrevious">
                        <input matEndDate placeholder="Data final" formControlName="dateEndPrevious">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matIconSuffix [for]="pickerPrevious"></mat-datepicker-toggle>
                      <mat-date-range-picker #pickerPrevious (selectionChange)="onPeriodChangePrevious($event)">
                        <mat-datepicker-actions>
                          <mat-form-field>
                            <mat-label>Selecione</mat-label>
                            <mat-select (selectionChange)="onPeriodChangePrevious($event)">
                              <mat-option value="1">Hoje</mat-option>
                              <mat-option value="7">Últimos 7 dias</mat-option>
                              <mat-option value="15">Últimos 15 dias</mat-option>
                              <mat-option value="30">Últimos 30 dias</mat-option>
                              <mat-option value="60">Últimos 60 dias</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <button mat-raised-button matDatepickerApply class="btnOk">OK</button>
                        </mat-datepicker-actions>
                      </mat-date-range-picker>
                    </mat-form-field>
                  </div>

                  <div class="field-date">
                    <mat-form-field>
                      <mat-label>Período atual</mat-label>
                      <mat-date-range-input [rangePicker]="picker" formGroupName="dateRange">
                        <input matStartDate placeholder="Data inicial" formControlName="dateStartCurrent">
                        <input matEndDate placeholder="Data final" formControlName="dateEndCurrent">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker (selectionChange)="onPeriodChangeCurrent($event)">
                        <mat-datepicker-actions>
                          <mat-form-field>
                            <mat-label>Selecione</mat-label>
                            <mat-select (selectionChange)="onPeriodChangeCurrent($event)">
                              <mat-option value="1">Hoje</mat-option>
                              <mat-option value="7">Últimos 7 dias</mat-option>
                              <mat-option value="15">Últimos 15 dias</mat-option>
                              <mat-option value="30">Últimos 30 dias</mat-option>
                              <mat-option value="60">Últimos 60 dias</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <button mat-raised-button matDatepickerApply class="btnOk">OK</button>
                        </mat-datepicker-actions>
                      </mat-date-range-picker>
                    </mat-form-field>
                  </div>

                  <div class="field">
                    <mat-form-field>
                      <mat-label>Canal</mat-label>
                      <mat-select formControlName="channel">
                        <mat-option [value]="-1">Todos</mat-option>
                        <mat-option *ngFor="let item of channelAttendant" [value]="item?._id">{{
                          item.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="field">
                    <mat-form-field>
                      <mat-label>Departamento</mat-label>
                      <mat-select formControlName="department">
                        <mat-option [value]="-1">Todos</mat-option>
                        <mat-option *ngFor="let item of department" [value]="item?._id">{{ item.name
                          }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="field">
                    <mat-form-field>
                      <mat-label>Atendente</mat-label>
                      <mat-select formControlName="attendant">
                        <mat-option [value]="-1">Todos</mat-option>
                        <mat-option *ngFor="let item of userAttendant" [value]="item?._id">{{ item.name
                          }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class=" h-[60%] rounded-lg btn-export">
                    <button
                      class="flex bg-blue-500 text-white px-4 py-2.8 hover:bg-blue-600 justify-center items-center rounded-lg"
                      (click)="exportFile()">
                      <i class="ph ph-export text-[24px]"></i>
                      Exportar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>



      <div class="cards-container">
        <mat-card class="card-container"
          (click)="openModalCountAttendance('channel', countItemsAttendance?.totalChannelIds)">
          <mat-card-header>
            <h2 class="title">Canais</h2>
          </mat-card-header>
          <mat-card-content>
            <div class="card-content">
              <span class="card-total">{{ countItemsAttendance?.totalChannels || 0 }}</span>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="card-container"
          (click)="openModalCountAttendance('department', countItemsAttendance?.totalDepartmentIds)">
          <mat-card-header>
            <h2 class="title">Departamentos</h2>
          </mat-card-header>
          <mat-card-content>
            <div class="card-content">
              <span class="card-total">{{ countItemsAttendance?.totalDepartments || 0 }}</span>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="card-container"
          (click)="openModalCountAttendance('user',  countItemsAttendance?.totalUserIds)">
          <mat-card-header>
            <h2 class="title">Atendentes</h2>
          </mat-card-header>
          <mat-card-content>
            <div class="card-content">
              <span class="card-total">{{ countItemsAttendance?.totalUsers || 0 }}</span>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="card-container"
          (click)="openModalCountAttendance('leadPrevious', countItemsAttendance?.attendanceIdsPrevious)">
          <mat-card-header>
            <h2 class="title">Atendimento (período anterior)</h2>
          </mat-card-header>
          <mat-card-content>
            <div class="card-content">
              <span class="card-total">{{ countItemsAttendance?.totalAttendancePrevious || 0 }}</span>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="card-container"
          (click)="openModalCountAttendance('leadCurrent', countItemsAttendance?.attendanceIdsCurrent)">
          <mat-card-header>
            <h2 class="title">Atendimento (período atual)</h2>
          </mat-card-header>
          <mat-card-content>
            <div class="card-content">
              <span class="card-total">{{ countItemsAttendance?.totalAttendanceCurrent || 0 }}</span>
            </div>
          </mat-card-content>
        </mat-card>


      </div>

      <div class="container">
        <div class="filter-container">
          <div class="flex w-[100%] h-[100%] rounded-lg">
            <div mat-button [matMenuTriggerFor]="menuGraphic"
              class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm  rounded-lg global-filter">
              <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                  fill="#667085" />
              </svg>
              <span class="truncate-text">{{ selectedGraphicLabel }}</span>
            </div>

            <mat-menu #menuGraphic="matMenu">
              <button mat-menu-item *ngFor="let type of typeCharts" [value]="type.value"
                (click)="onGraphicSelect(type.value, type?.name)">
                {{ type.name }}
              </button>
            </mat-menu>
          </div>

          <div class="flex w-[100%] h-[100%] rounded-lg">
            <div mat-button [matMenuTriggerFor]="menuList"
              class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm  rounded-lg global-filter">
              <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                  fill="#667085" />
              </svg>
              <span class="truncate-text">{{ selectedListLabel }}</span>
            </div>

            <mat-menu #menuList="matMenu">
              <button mat-menu-item *ngFor="let type of typeCharts" [value]="type.value"
                (click)="onListSelect(type.value, type?.name)">
                {{ type.name }}
              </button>
            </mat-menu>
          </div>


          <!-- <mat-form-field appearance="fill" class="filter-field">
            <mat-label>Gráfico</mat-label>
            <mat-select [(value)]="selectedGraphic">
              <mat-option *ngFor="let type of typeCharts" [value]="type.value">
                {{ type.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="filter-field">
            <mat-label>Lista</mat-label>
            <mat-select [(value)]="selectedList">
              <mat-option *ngFor="let type of typeCharts" [value]="type.value">
                {{ type.name }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>

        <div class="chart-list-container">
          <div class="charts">
            <ngx-charts-bar-horizontal *ngIf="selectedGraphic == 'channel'" [view]="view" [results]="graphicChannel"
              [xAxis]="true" [yAxis]="true" [showXAxisLabel]="true" [showYAxisLabel]="true" [scheme]="colorScheme">
            </ngx-charts-bar-horizontal>

            <ngx-charts-bar-horizontal *ngIf="selectedGraphic == 'user'" [view]="view" [results]="graphicUser"
              [xAxis]="true" [yAxis]="true" [showXAxisLabel]="true" [showYAxisLabel]="true" [scheme]="colorScheme">
            </ngx-charts-bar-horizontal>

            <ngx-charts-bar-horizontal *ngIf="selectedGraphic == 'department'" [view]="view"
              [results]="graphicDepartment" [xAxis]="true" [yAxis]="true" [showXAxisLabel]="true"
              [showYAxisLabel]="true" [scheme]="colorScheme">
            </ngx-charts-bar-horizontal>

          </div>

          <div class="list">
            <table class="min-w-full divide-gray-100 divide-y-[3px]" *ngIf="selectedList == 'channel'">
              <thead class="bg-gray-50">
              </thead>
              <tbody class="divide-gray-100 divide-y-[3px] bg-white">

                <tr class="rounded-none" *ngFor="let item of graphicChannel">
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p>{{item?.name}}</p>
                  </td>
                  <td class="px-3 py-4 text-sm">
                    <p>{{item?.value}}</p>
                  </td>
                </tr>
                <tr class=""></tr>
              </tbody>
            </table>

            <table class="min-w-full divide-gray-100 divide-y-[3px]" *ngIf="selectedList == 'user'">
              <thead class="bg-gray-50">
              </thead>
              <tbody class="divide-gray-100 divide-y-[3px] bg-white">

                <tr class="rounded-none" *ngFor="let item of graphicUser">
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p>{{item?.name}}</p>
                  </td>
                  <td class="px-3 py-4 text-sm">
                    <p>{{item?.value}}</p>
                  </td>
                </tr>
                <tr class=""></tr>
              </tbody>
            </table>

            <table class="min-w-full divide-gray-100 divide-y-[3px]" *ngIf="selectedList == 'department'">
              <thead class="bg-gray-50">
              </thead>
              <tbody class="divide-gray-100 divide-y-[3px] bg-white">

                <tr class="rounded-none" *ngFor="let item of graphicDepartment">
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p>{{item?.name}}</p>
                  </td>
                  <td class="px-3 py-4 text-sm">
                    <p>{{item?.value}}</p>
                  </td>
                </tr>
                <tr class=""></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <div class="container">
        <div *ngIf="pager.total > 0">
          <mat-card class="birth">
            <mat-card-content class="content-bird">
              <div class="table-container">
                <table class="min-w-full divide-gray-100 divide-y-[3px]">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nome do
                        canal</th>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">
                        Departamento</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
                        Atendente</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
                        Atendimento (período anterior)</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
                        Atendimento (período atual)</th>

                    </tr>
                  </thead>
                  <tbody class="divide-gray-100 divide-y-[3px] bg-white">

                    <tr class="rounded-none" *ngFor="let item of pager.list">
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <p>{{item?.channel}}</p>
                      </td>
                      <td class="px-3 py-4 text-sm">
                        <p>{{item?.department}}</p>
                      </td>
                      <td class="px-3 py-4 text-sm">
                        <p>{{item?.user}}</p>
                      </td>
                      <td class="px-3 py-4 text-sm">
                        <p>{{item?.totalAttendancePrevious}}</p>
                      </td>
                      <td class="px-3 py-4 text-sm">
                        <p>{{item?.totalAttendanceCurrent}}</p>
                      </td>

                    </tr>
                    <tr class=""></tr>
                  </tbody>
                </table>
              </div>
            </mat-card-content>
            <paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-3 mb-3" [pager]="pager"
              (onPager)="loadPage($event)">
            </paginator>
          </mat-card>


        </div>

        <div class="card-container-wrapper-campaing" *ngIf="pager.total == 0">
          <mat-card class="card-container-campaing-not">
            <mat-card-header>
              <mat-card-title class="titleChannel">Nenhum registro encontrado</mat-card-title>
            </mat-card-header>
            <mat-card-content class="content-card">
              <div class="metrics-wrapper">
                <div class="metric">
                  <span>Altere no filtro para buscar mais resultados!</span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </section>
  </div>
</div>